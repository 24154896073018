import { useNavigate } from "react-router-dom";
import { useState,useEffect } from "react";
import { Parser } from "../../lib/editor/functions";
import './home.css';
import Footer from "../../lib/nav/Footer";
import { Credentials } from "../../lib/account/fn_login";

export default function Home() {
    let navigate = useNavigate();

    // LOAD THE DATA -->
    const [data,setData] = useState([]);
    const [fr,setFR] = useState(false);
    useEffect(() => setFR(true),[]);
    useEffect(() =>{
        if(fr){
            GetData();
            setFR(false);
        }
    },[fr]);

    const GetData = async () => {
        const request = await fetch("db/home.json",{cache:'no-cache'});
        if(request.ok){
            const rd = await request.json();

            for(let i = 0;i< rd.length;i++){
                let p = new Parser(rd[i].body);
                rd[i].link = p.HasObject('$LINK') ? p.GetObjects('$LINK')[0] : '/';
                rd[i].body = p.HasObject('$TEXT') ? p.GetObjects('$TEXT').join(' ') : '';
            }
            setData(rd.sort((a,b) => new Date(b.date).getTime() - new Date(a.date).getTime()).filter(x => x.visible === true));
        }
        else console.log("Could not fetch data: " + request.text());
    }
    // <-- LOAD THE DATA

    return (
        <>
            <div className="home-overlay" />

            <div className="home-title">
                <div>
                    <h1>ASTNERS 
                        {Credentials.isValid() && <button className="icon edit" onClick={() => navigate("/home-edit")} />} 
                    </h1>
                    <hr />
                    <h2>SPÄNNANDE PROJEKT, DEMOS OCH LITE ANNAT</h2>
                </div>
            </div>

            {data.map((item, index) => 
                <a className="home-item" key={index} href={item.link} target={item.link ? (item.link.search("http") === 0 ? "_blank" : "_self") : "_self"}>
                    <div className="overlay"  />
                    <img src={item.image} />
                    <div>
                        <span>{item.title}</span>
                        {item.body.split('\n').map((str,six) => str.trim().length > 0 && <p key={six}>{str}</p>)}
                    </div>
                </a>
            )}

            <Footer />
        </>
    );
}