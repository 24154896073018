import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Navbar from './lib/nav/Navbar';
import Editor from './lib/editor/editor';
// pages
import About from './pages/about/about';
import Ml from './pages/ml/ml';
import Subjective from './pages/analysis/subjectiv';
import Game from './pages/game/game';
import Home from './pages/home/home';
import Account from './pages/account/account';



export default function App() {
  
  const api = 'https://api.astner.info';
  //const api = 'http://127.0.0.1:5000';

  return (
    <BrowserRouter>
        
        <Navbar fixed={true} />
        <img src='images/bg.jpg' className='bg-fixed' />

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/home-edit' element={<Editor apiUrl={api} web={'astner.info'} title={'Edit Home'} dataDir={'/db'} fileDir={'/db'} name={'home'} />} />

          <Route path='/subjective-analysis' element={<Subjective />} />
          <Route path='/subjective-analysis-edit' element={<Editor apiUrl={api} web={'astner.info'} title={'Edit Analysis'} dataDir={'/db'} fileDir={'/db'} name={'analysis'} />} />

          <Route path='/machine-learning-demo' element={<Ml />} />
          <Route path='/machine-learning-demo-edit' element={<Editor apiUrl={api} web={'astner.info'} title={'Edit Machine Learning'} dataDir={'/db'} fileDir={'/db'} name={'ml'} />} />

          <Route path='/peter-astner' element={<About />} />
          <Route path='/peter-astner-edit' element={<Editor apiUrl={api} web={'astner.info'} title={'Edit About'} dataDir={'/db'} fileDir={'/db'} name={'about'} />} />

          <Route path='/login' element={<Account api={api} page={"login"} />} />
          <Route path='/account' element={<Account api={api} page={"account"} />} />

          <Route path='/games' element={<Game />} />
        </Routes>
        
    </BrowserRouter>
  );
}
