import { useNavigate } from "react-router-dom";
import Footer from "../../lib/nav/Footer";
import { useState, useEffect } from "react";
import { Parser } from "../../lib/editor/functions";
import { Credentials } from "../../lib/account/fn_login";

export default function Subjective() {
    let navigate = useNavigate();

    // LOAD THE DATA -->
    const [data,setData] = useState([]);
    const [fr,setFR] = useState(false);
    useEffect(() => setFR(true),[]);
    useEffect(() =>{
        if(fr){
            GetData();
            setFR(false);
        }
    },[fr]);

    const GetData = async () => {
        const request = await fetch("db/analysis.json",{cache:'no-cache'});
        if(request.ok){
            const rd = await request.json();
            for(let i = 0;i< rd.length;i++){
                let p = new Parser(rd[i].body);
                rd[i].body = p.ParsedText; 
                if(p.HasObject('$IMAGE')) rd[i].ImageText = p.GetObjects('$IMAGE');
            }
            setData(rd.sort((a,b) => new Date(b.date).getTime() - new Date(a.date).getTime()).filter(x => x.visible === true));
        }
        else console.log("Could not fetch data: " + request.text());
    }
    // <-- LOAD THE DATA

    return (
        <div className="page">
        <div className="grow bg-white-t main">
            <h1>
                SUBJEKTIV ANALYS
                {Credentials.isValid() && <button className="icon edit" onClick={() => navigate("/subjective-analysis-edit")} />}
            </h1>
            {data.map((block, bix) => 
                <div className="content" key={bix}>
                    <h2>{block.title}</h2>
                    {block.image && <img src={block.image} className='right mobile-bar' />}

                    {block.body.map((obj,oix) => 
                        <div key={oix}>
                            {["$H3","$H"].includes(obj.id) && <h3>{obj.value}</h3>}
                            { obj.id === "$TEXT" && obj.value.split('\n').map((str,strix) => str.trim().length > 0 && <p key={strix}>{str}</p>)}
                        </div>
                    )}
                    
                </div>
            )}

        </div>
        <Footer />
    </div>
    );
}