import {useState} from 'react';
import {useNavigate,Link} from 'react-router-dom';
import {Credentials} from '../account/fn_login';
import './Navbar.css';
import data from './settings.json'
import menu0 from './menu-0.svg';
import menu1 from './menu-1.svg';

export default function Navbar({transparent=false, fixed=false, overlay=false}){
    let navigate = useNavigate(); // Must be enclosed withing router-tags to work
    const [clicked,setClicked] = useState(false);

const redirect = (path) => {setClicked(false);navigate(path);}

    // NAVBAR
    return (
        //  MAIN CONTAINER
        <div className={overlay ? null : 'navbar navbar-H'}>
            <div className={'navbar navbar-H' + (transparent ? ' transparent':'') + (fixed ? ' fixed':'') }>
            
            {/* CONTENT */}
            <div className='navbar-content navbar-W'>
                    
                    <div className='navbar-logo navbar-H' onClick={() => redirect('/')}>
                        <img src='./logo192.png' alt={data.imgseo.alt} title={data.imgseo.title} /> 
                        <span>{data.title}</span>
                    </div>

                    {/* div is used for flex-center X and bar icon */}
                    <div className='navbar-menu-btn'>
                        <img src={clicked ? menu1 : menu0} alt={data.imgseo.alt} title={data.imgseo.title} onClick={() => setClicked(a => !a)} />
                    </div>

                    <ul className={(clicked ? null : 'hide')}>
                        {data.links.map((a, i) => (
                            <li className='navbar-link' key={i} onClick={() => redirect(a.to) }>
                                <Link to={a.to} onClick={() => setClicked(false)}>{a.name}</Link>
                            </li>
                        ))}
                        
                        <li className='navbar-link-login' onClick={() => redirect(Credentials.isValid() ? "/account" : "/login") }>{Credentials.isValid() ? 'KONTO' : 'LOGIN'}</li>
                        
                    </ul>
                
                </div>
                {/* CONTENT */}

            </div>
        </div>
        // MAI CONTAINER
    );
}

