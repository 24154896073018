 function datePartsStr(strDate)
 {
     console.log(strDate);
     console.log(strDate.getMilliseconds());
     var d = new Date(strDate);
     var da = [];
     if(!isNaN(d.getFullYear())) da.push(d.getFullYear());
     if(!isNaN(d.getMonth())) da.push(d.getMonth()+1);
     if(!isNaN(d.getDate())) da.push(d.getDate());
     if(!isNaN(d.getHours())) da.push(strDate.length>11 ? d.getHours() : 0);
     if(!isNaN(d.getMinutes())) da.push(d.getMinutes());
     if(!isNaN(d.getSeconds())) da.push(d.getSeconds());
     if(!isNaN(d.getMilliseconds())) da.push(d.getMilliseconds());
     return da;
 }
 function dateParts(date)
 {
     var da = [];
     if(!isNaN(date.getFullYear())) da.push(date.getFullYear());
     if(!isNaN(date.getMonth())) da.push(date.getMonth()+1);
     if(!isNaN(date.getDate())) da.push(date.getDate());
     if(!isNaN(date.getHours())) da.push(date.getHours());
     if(!isNaN(date.getMinutes())) da.push(date.getMinutes());
     if(!isNaN(date.getSeconds())) da.push(date.getSeconds());
     if(!isNaN(date.getMilliseconds())) da.push(date.getMilliseconds());
     return da;
 }

export const getDate = (addDays) => 
{
    if(addDays === null || addDays === undefined) addDays = 0;
     var d = new Date();
     d.setDate(d.getDate() + addDays);
     return d;
}

export const evalDateString = (strDate) =>  {
    var d = strDate.substring(0,10).split('-');
    var dCheck = (d.length === 3 ? d[1].length === 2 && d[2].length === 2 : false);
    var tCheck = true;
    if(strDate.trim().length > 11)
        strDate.substring(11,11+8).split(':').forEach(i => { if(i.trim().length !== 2) tCheck = false; });
    return dateParts(new Date(strDate)).length > 0 && !(strDate.indexOf(': ') > 0 || strDate.indexOf('. ') > 0 || strDate.indexOf('  ') > 0) && dCheck && tCheck; 
}

export const isoDateString = (date) => 
{ 
     const toAlpha = (n) => { n = n.toString();while(n.length < 2) n = '0' + n; return n;};
     var d = dateParts(date);
     if(d.length === 7)
     {
         // remove zero's at end
         while(d[d.length-1] === 0 && d.length > 3) d.pop();
         // make alpha-numeric
         for(var x=0;x<d.length;x++) if(x !== 0 && x !== 6) d[x] = toAlpha(d[x]);
         var str = [...d].splice(0,3).join('-'); // date
         // handle time if exist
         if(d.length > 3) str = str + ' ' + [...d].splice(3,3).join(':') + (d.length === 7 ? `.${d[6]}` : '');
         return str;
     }
     return '';
}

export const isNumber = (value) => { return !isNaN(value) && value !== ''; }

export const tryGetValue = (key,itemObject, defaultValue) => {
    if(itemObject !== undefined)
        if(itemObject[key] !== undefined && itemObject[key] !== null)
            return itemObject[key];
    return defaultValue || '';
}

export const hasKeyData = (item, key) => {
    if(item !== undefined)
        if(item[key] !== undefined)
            if(item[key] !== null)
                if(item[key].toString().length > 0)
                    return true;
    return false;
}

export const getFileExtension = (filepath) => {
    if(filepath === undefined || filepath === null) return ''
    const nameparts = filepath.split('.');
    if (nameparts.length > 1)
    {
        const x = nameparts[nameparts.length - 1];
        if(x.length <= 4 && nameparts[nameparts.length - 2].length > 0)
            return "." + x;
    }
    return '';
}

export const isNullOrEmpty = (string) => {
    if(string === undefined)
        return  true;
    if(string === null)
        return true;
    if(string.length === 0)
        return true;
    return false;
}

export const MakePath = (paths = []) => {
    const p = [];
    paths.forEach(item => {
        item = item.replace('\\','/');
        if(item[0] !== '/') item = '/' + item;
        if(item[item.length-1] === '/') item = item.substring(0, item.length -1);
        p.push(item);
    })
    return p.join('');
}

export class Parser{
    constructor(text){
        this.text = text;
        this._Parse();
    }

    // content
    text = '';
    ParsedText = []
    
    GetHeadersAndText = (text_keys = ['$TEXT'], hdr_keys = ['$H1','$H2','$H3'], ) => {
        var o = [], header = '';
        this.ParsedText.forEach((item,index) => {

            if(hdr_keys.includes(item.id) && header.length === 0)
                header =  item.value;
            else if (hdr_keys.includes(item.id) && header.length > 0)
            {
                o.push({title:header, text:''});
                header = item.value;
            }
            else if (text_keys.includes(item.id) && header.length === 0)
            {
                o.push({title:'', text:item.value});
            }
            else if (text_keys.includes(item.id) && header.length > 0)
            {
                o.push({title:header, text:item.value});
                header = '';
            }
        });
        return o;
    } 

    GetObjects = (id) => {
        var o = [];
        for(var x =0;x<this.ParsedText.length;x++){
            if(this.ParsedText[x].id === id)
                o.push(this.ParsedText[x].value);
        }
        return o;
    }

    HasObject = (id) => {
        for(var x=0;x<this.ParsedText.length;x++)
            if(id === this.ParsedText[x].id)
                return true;
        return false;
    }

    _Parse = () => {
        // anchor
        var anchorId = '';
        var anchor = false;
        var escape = false;
        var sb = [];

         for(var x=0;x<this.text.length;x++){
            const char = this.text[x];

            if(char === '$') {
                escape = true;
                anchor = false;
                this.ParsedText.push({id:'$TEXT',value:sb.join('')});
                sb = ['$'];
            }
            else if(escape && !anchor && char === '(') {
                anchor = true;
                anchorId = sb.join('');
                sb = []; 
            }
            else if(escape && anchor && char ===')') {
                anchor = false;
                escape = false;
                this.ParsedText.push({id:anchorId, value:sb.join('')});
                sb = [];
            }
            else {
                sb.push(char);
            }
         }
         if(sb.length > 0) this.ParsedText.push({id:'$TEXT',value:sb.join('')});
    }
}