import { LoginDialogue,ManageAccount } from "../../lib/account/fn_login";
import './account.css'

export default function Account({api='', page='login'}) {
    return (
        <div className="account">
            {page==="login" && <LoginDialogue apiUrl={api} redirect={'/'} webId={'astner.info'} onlyLogin={true} />}
            {page === "account" && <ManageAccount apiUrl={api} redirect={'/'} webId={'astner.info'} onlyLogin={true} />}
        </div>
    );
}