import {useEffect, useState, useRef} from 'react';
import './game.css';
import Footer from '../../lib/nav/Footer';
import { useNavigate } from 'react-router-dom';

const Buttons = ({alpha_enabled = false, returnHandler, disabled = false}) => {
    const [value,setValue] = useState('-');
    let navigate = useNavigate();

    const goEnter = () => { 
        if(value !== '-')
        {
            let a = value;
            setValue('-');
            return returnHandler(a); 
        }
            
    }

    const addValue = (v) => {
        if(alpha_enabled)
            setValue(a => a === '-' ? v.toString() : a.toString() + v.toString() );
        else
        {
            if(value === '0' || value === '-') 
                setValue(v.toString());
            else
                setValue(a => a.toString() + v.toString() );
        }
    }

    return(
        <div>
            <div className='numpad'>
                {disabled && <div className='numpad-disabled' />}
                <div className="numpad-row">
                    <div className="value">{value}</div>
                </div>
                <div className="numpad-row">
                    <button onClick={() => goEnter() } className='enter'>ENTER</button>
                    <button onClick={() => setValue('-')} className='delete'>X</button>
                </div>
                <div className="numpad-row">
                    <button onClick={() => addValue(1) }>1</button>
                    <button onClick={() => addValue(2) }>2</button>
                    <button onClick={() => addValue(3) }>3</button>
                </div>
                <div className="numpad-row">
                    <button onClick={() => addValue(4) }>4</button>
                    <button onClick={() => addValue(5) }>5</button>
                    <button onClick={() => addValue(6) }>6</button>
                </div>
                <div className="numpad-row">
                    <button onClick={() => addValue(7) }>7</button>
                    <button onClick={() => addValue(8) }>8</button>
                    <button onClick={() => addValue(9) }>9</button>
                </div>
                <div className="numpad-row">
                    <button onClick={() => addValue(0) }>0</button>
                </div>
            </div>
            <div className='numpad-row-bottom'>
                <button onClick={() => returnHandler("exit")}>TILLBAKA</button>
            </div>
        </div>
    );
}

function randomInt(min, max, except = null) { // min and max included 
    if(except !== null && min < max)
    {
        let a = except;
        while(a === except)
        {
            //console.log(`a:${a}, except:${except}`);
            a = Math.floor(Math.random() * (max - min + 1) + min); 
        }
        return a;
    }
    else
        return Math.floor(Math.random() * (max - min + 1) + min); 
}

export function MathGame({level=1, operators=['/'], setActive}){
    const [Q,setQ] = useState({X:0, Y:0, O:'+'});
    const [score,setScore] = useState({W:0, L:0});
    const oRef = useRef(); // use to fade out correct bool (skull or happy)
    const rRef = useRef(); // use to show/hide result

    useEffect(() => {newQuestion();window.scrollTo(0,0);},[]);

    const setAddition = () => {
        let x,y;
        switch(level)
        {
            case 1:
                x = randomInt(3,9);
                y = randomInt(3,9, x)
                break;
            case 2:
                x = randomInt(9,59);
                y = randomInt(9,19, x);
                break;
            case 3:
                x = randomInt(19,99);
                y = randomInt(19,99, x);
                break;
            default:
                x = randomInt(109,599);
                y = randomInt(109,599, x);
                break;
        }
        setQ({X:x, Y:y, O:"+", A:x+y});
    }

    const setSubtraction = () => {
        let x,y;
        switch(level)
        {
            case 1:
                x = randomInt(6,19);
                y = randomInt(4,x, x);
                break;
            case 2:
                x = randomInt(19,59);
                y = randomInt(9,x, x);
                break;
            case 3:
                x = randomInt(59,199);
                y = randomInt(49,x, x);
                break;
            default:
                x = randomInt(209,599);
                y = randomInt(109,x, x);
                break;
        }
        setQ({X:x, Y:y, O:"-", A:x-y});
    }

    const setMultiplication = () => {
        let x,y;
        switch(level)
        {
            case 1:
                x = randomInt(2,9);
                y = randomInt(2,9);
                break;
            case 2:
                x = randomInt(2,4);
                y = randomInt(11,19);
                break;
            case 3:
                x = randomInt(2,5);
                y = randomInt(11,59);
                break;
            default:  
                x = randomInt(2,9);
                y = randomInt(11,59);
                break;
        }
        setQ({X:x, Y:y, O:"*", A:x*y});
    }

    const setDivition = () => {
        const getBase = (t) => {
            let ba = [];
            for(let _i=2;_i<t;_i++)
            {
                let b = t / _i;
                if(Math.round(b) === b)
                    ba.push(_i);
            }
            if(ba.length === 0)
                return 0;
            return ba[randomInt(0,ba.length-1)];
        }

        let x,y,ok = false;
        while(!ok)
        {
            switch(level)
            {
                case 1:
                    x= randomInt(6, 19);
                    y= getBase(x);
                    break;
                case 2:
                    x= randomInt(19, 59);
                    y= getBase(x);
                    break;
                case 3:
                    x= randomInt(49, 159);
                    y= getBase(x);
                    break;
                default:
                    x= randomInt(109, 1999);
                    y= getBase(x);
                    break;
            }
            if(y !== 0 && y !== 1)
                ok = true;
        }
        setQ({X:x, Y:y, O:'/', A:x/y});
    }

    const newQuestion = () => {
        switch(operators[randomInt(0,operators.length-1)])
        {
            case "/": setDivition();break;
            case "-": setSubtraction();break;
            case "*": setMultiplication();break;
            default: setAddition();break;
        }
        oRef.current.className = "outcome hide";
        rRef.current.className = "show";
    }

    const answerHandler = (answer) => {
        try{
            if(answer === "exit")
                setActive(false);

            let answerInt = parseInt(answer);
            let correct = Q.A === answerInt;
            rRef.current.className = "hide";
            oRef.current.className = correct ? 'outcome win show' : 'outcome lose show';
            setScore(c => {
                let n = {...c};
                if(correct)
                    n.W += 1;
                else
                    n.L += 1; 
                return n;
            })
        }
        catch(e)
        {console.log('Could not parse result');}
        const nq = setTimeout(() => newQuestion(), 600);
        return () => {clearTimeout(nq)}
    }

    return (
        <div className="game page">

            <div className='score'>
                    <div> 🤩{score.W} </div>
                    <div> 💀{score.L} </div>
            </div>

            <div className="grow game-window">
                <div ref={rRef}>{`${Q.X} ${Q.O} ${Q.Y}`}</div>
                <div ref={oRef} />
            </div>

            <Buttons returnHandler={answerHandler} />
        </div>
    );
}

export function MemGame({seconds = 2, strLength = 2, itterations = 2, setActive}){
    const [score,setScore] = useState({W:0, L:0});
    const [Q,setQ] = useState([]);
    const [show,setShow] = useState(true);
    const [ix,setIX] = useState(0);
    const oRef = useRef();

    useEffect(() => {createQuestion();window.scrollTo(0,0);}, []);

    useEffect(()=>{
        if(show)
        {
            const delay = (ix < Q.length ? (Q[ix].length === 0 || Q[ix].toLowerCase() === "ready" ? 1: seconds) : 1) * 1000; // seconds
            const run = setTimeout(() => showQuestion(), delay);
            return () => {clearTimeout(run)}
        }
        
    });

    const showQuestion = () => {
        if(ix < Q.length)
            setIX(x => x +1);
        else 
        {
            setShow(false);
            oRef.current.className ='outcome hide';
        }
            
    }

    const createQuestion = () => {
        let ai = ["", "ready"];
        for(let _i0=0;_i0 < itterations;_i0++)
        {
            let an = [];
            for(let _i = 0;_i<strLength;_i++)
                an.push(randomInt(0,9).toString());
            ai.push('');
            ai.push(an.join(''));
        }
        setQ(ai);
        setIX(0);
        setShow(true);
    }

    const answerHandler = (answer) => {
        if (answer === "exit")
            setActive(false);
        
        let A = [...Q];
        A.splice(0,2);

        const correct = answer === A.join('');
        setScore(c => {
            let n = {...c};
            n.W += correct ? 1 : 0;
            n.L += correct ? 0 : 1;
            return n;
        })

        oRef.current.className = `outcome ${correct ? 'win' : 'lose'} show`;

        createQuestion();
    }

    return (
        <div className='game page'>
            <div className='score'>
                <div> 🤩{score.W} </div>
                <div> 💀{score.L} </div>
            </div>
            <div className='game-window grow'>
                {Q[ix]}
                <div ref={oRef} />
            </div>

            <Buttons returnHandler={answerHandler} alpha_enabled={true} disabled={show} />

        </div>
    );
}


export default function GameSelect(){
    const [play,setPlay] = useState(false);
    const [gameId,setGameId] = useState(0);
    // mattespel
    const [level,setLevel] = useState(2);
    const [operator,setOperator] = useState(['+','-']);
    // mem-spel
    const [memLength,setMemLength] = useState(2);
    const [memItt,setMemItt] = useState(3);
    const [memSec,setMemSec] = useState(1);


    useEffect(() => {setPlay(false);window.scrollTo(0,0)} ,[]);

    const handleOperator = (o) => {
        setOperator(c => {
            let n = [...c];
            let ix = c.indexOf(o);
            if(ix >=0) 
                n.splice(ix,1);
            else
                n.push(o);
            return n;
        });
    }

    const ViewConfig = () => {
        return (
            <div className='game'>
                <br /><br />
                <div className='game-select'>
                    <h2>TRÄNA MATTE</h2>
                    <h3>Svårighetsnivå</h3>
                    <ul className='button-group'>
                        {[1,2,3,4].map((item,key) => 
                        <li 
                            key={key} 
                            className={item === level ? 'active' : null}
                            onClick={() => setLevel(item)}
                        >
                            Nivå {item}
                        </li>)}
                    </ul>
    
                    <h3>Räknesätt</h3>
                    <ul className='button-group large-text'>
                        {["+","-","*","/"].map((item, index) => 
                            <li key={index}
                                className={operator.includes(item) ? 'active' : null}
                                onClick={() => handleOperator(item) }
                            >
                                {item}
                            </li>
                        )}
                    </ul>
    
                    <br /><br />
    
                    <div style={{width:'100%', textAlign:'center'}}>
                        <button 
                            disabled={operator.length === 0}
                            className='start-button' 
                            onClick={() => {setGameId(1);setPlay(true)}}>
                                STARTA
                        </button>
                    </div> 
    
                </div>

                <div className='game-select'>
                    <h2>TRÄNA SIFFERMINNE</h2>
                    <h3>Sifferstorlek: {memLength}</h3>
                    <input type='range' min="1" max="10" value={memLength} onChange={(e) => setMemLength(e.target.value)} />
                    <h3>Sifferantal: {memItt}</h3>
                    <input type='range' min="1" max="10" value={memItt} onChange={(e) => setMemItt(e.target.value)} />
                    <h3>Visas i {memSec} sekunder</h3>
                    <input type='range' min="1" max="5" value={memSec} onChange={(e) => setMemSec(e.target.value)} />
                    <br /><br />
                    <div style={{width:'100%', textAlign:'center'}}>
                        <button className='start-button' onClick={() => {setGameId(2);setPlay(true);}}>STARTA</button>
                    </div>
                </div>


                <Footer />            
    
            </div>);
    }

    return play ? 
        (gameId == 1 ? <MathGame level={level} operators={operator} setActive={setPlay} /> : <MemGame strLength={memLength} itterations={memItt} seconds={memSec} setActive={setPlay} />)
        : ViewConfig()

}

