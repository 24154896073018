import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {Parser} from '../../lib/editor/functions';
import { Credentials } from "../../lib/account/fn_login";
import './ml.css';
import Fraud from './fraud';
import Risk from './risk';
import Footer from "../../lib/nav/Footer";

export default function Ml({api}){
    const [data,setData] = useState([]);
    const [fr,setFR] = useState(false);
    let navigate = useNavigate();

    // LOAD THE DATA -->
    useEffect(() => setFR(true),[]);
    useEffect(() =>{
        if(fr){
            GetData();
            setFR(false);
        }
    },[fr]);

    const GetData = async () => {
        const request = await fetch("db/ml.json",{cache:'no-cache'});
        if(request.ok){
            const rd = await request.json();
            for(let i = 0;i< rd.length;i++){
                let p = new Parser(rd[i].body);
                rd[i].body = p.ParsedText;
                if(p.HasObject('$IMAGE')) rd[i].ImageText = p.GetObjects('$IMAGE');
            }
            setData(rd.sort((a,b) => new Date(b.date).getTime() - new Date(a.date).getTime()).filter(x => x.visible === true));
        }
        else console.log("Could not fetch data: " + request.text());
    }
    // <-- LOAD THE DATA


    return(
        <div className="page">
            <div className="grow bg-white-t main">
                <h1>
                    MACHINE LEARNING DEMO
                    {Credentials.isValid() && <button className="icon edit" onClick={() => navigate("/machine-learning-demo-edit")} />}
                </h1>
                {data.map((block, bix) => 
                    ["RISK-KOMPONENT", "FRAUD-KOMPONENT"].includes(block.title) ? 
                        block.title === "RISK-KOMPONENT" ? <Risk apiUrl={api} key={'risk_' +bix.toString()} /> : <Fraud apiUrl={api} key={'fraud_' + bix.toString()} />
                    :
                    <div className="content" key={'txt_' + bix.toString()}>
                        <h2>{block.title}</h2>
                        {block.body.map((obj,oix) => 
                            <div key={oix}>
                                {["$H3","$H"].includes(obj.id) && <h3>{obj.value}</h3>}
                                {obj.id === "$TEXT" && obj.value.split('\n').map((str,strix) => str.trim().length > 0 && <p key={strix}>{str}</p>)}
                            </div>
                        )}
                        {block.image && <a href={block.image} target='_blank'><img src={block.image} className='center mobile-bar' /></a>}
                        {block.ImageText && <div className="ml-img-text">{block.ImageText}</div>}
                    </div>
                )}

            </div>
            <Footer />
        </div>
    );
}