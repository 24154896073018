import { useState,useEffect } from "react";
import {Credentials} from '../../lib/account/fn_login';
import { useNavigate } from "react-router-dom";
import Footer from "../../lib/nav/Footer";

export default function About(){
    const [data,setData] = useState([]);
    let navigate = useNavigate();

    const [fr,setFR] = useState(false); // firstRender
    useEffect(() => setFR(true),[]);
    useEffect(() => {
        if(fr)
        {
            GetData();
            setFR(false);
        }
    },[fr]);

    const GetData = async () => {
        const request = await fetch('/db/about.json', {cache:'no-cache'});
        if(request.ok)
        {
            const rdata = await request.json();
            setData(rdata);
        }
        else
            console.log('Could not fetch data: ' + request.text());
    }


    return (
        <div className="page">
            <div className="grow main bg-white-t">
                <h1>
                    PETER ASTNER 
                    {
                    Credentials.isValid() && <button 
                    className="icon edit" 
                    onClick={() => navigate("/peter-astner-edit")}
                    />
                    }
                </h1>
                {
                    data.map((item, index) => 
                        <div className="content" key={index}>
                            <img src={item.image}  className='right' />
                            <h2>{item.title}</h2>
                            {item.body.split('\n').map((str,six) => str.trim().length > 0 && <p key={six}>{str}</p>)}
                        </div>
                    )
                }

            </div>

            <Footer />
        </div>
    );
}