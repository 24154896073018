import React from 'react'
import './Footer.css'
import data from './settings.json'

export default function Footer(){
    const logo = './logo192.png';

    return (
        <div className='footer'>
            <div className='footer-content'>
                {/* LOGO-INFO */}
                <div className='footer-logo'>
                    <img src={logo} alt='peter astner' title='astner' />
                    <div className='footer-info'>
                        <span className='footer-title'>{data.title}</span>
                        <span>{data.email}</span>
                    </div>
                </div>

                {/* LINKS */}
                <div  className='footer-links'>
                    <span style={{fontWeight:700}}>Länkar</span>
                    <ul>
                    {
                        data.links.map((itm,ix) => 
                        <li key={ix}>
                            <a href={itm.to} className='text-default'>{itm.name}</a> 
                            
                            {/*<span className='text-devider'> / </span>
                            <a href={itm.to} className='text-small'>{itm.name}</a>*/}
                        </li>
                        )
                    }
                </ul>
                </div>
                
            </div>
        </div>
    );
}