import {useState, useEffect} from 'react';
import imgLoad from './loader.gif';


export default function Risk({apiUrl}){
    const [gender,setGender] = useState('male');
    const [age,setAge] = useState('young');
    const [area,setArea] = useState('good');
    const [income,setIncome] = useState('low');
    const [car,setCar] = useState('old');
    
    const [score,setScore] = useState('-');
    const [load,setLoad] = useState(false);

    async function evalRisk(o){
        setLoad(true);
        const json = JSON.stringify(o);
        try{
            const request = await fetch(apiUrl + "/astner.info/risk", {method:"post", body:json, headers:{"Content-Type":"application/json"}});
            if(request.ok)
            {
                var rData = await request.json();
                setScore(`Class: ${rData["Name"]} - ${rData["Description"]}`);
            }
            else
                setScore("(could not parse item)");
        }
        catch(e){
            setScore('(api failure)');
        }
        setLoad(false);
    }

    const getObject = () => {
        return { "gender": gender, "age":age, "area":area, "income":income, "car":car};
    }

    const getRisk = () => {
        //console.log(getObject());
        evalRisk(getObject());
    }


    return (
        <div className='component'>
            <h2>RISK ASSESSMENT DEMO</h2>
 
            <div className="form">
                <h3>About You</h3>

                <div className="form-row">

                    <div>
                        <span>Gender</span>
                        <select value={gender} onChange={(e) => setGender(e.target.value)}>
                            <option value='male'>Male</option>
                            <option value='female'>Female</option>
                        </select>
                    </div>

                    <div>
                        <span>Age</span>
                        <select value={age} onChange={(e) => setAge(e.target.value)}>
                            <option value='young'>Young</option>
                            <option value='middle'>Middle</option>
                            <option value='old'>Old</option>
                        </select>
                    </div>

                    <div>
                        <span>Income level</span>
                        <select value={income} onChange={(e) => setIncome(e.target.value)}>
                            <option value='low'>Low</option>
                            <option value='middle'>Middle</option>
                            <option value='high'>High</option>
                            <option value='lyx'>Very high</option>
                        </select>
                    </div>

                </div>

                <div className="form-row">

                    <div>
                        <span>Area</span>
                        <select value={area} onChange={(e) => setArea(e.target.value)}>
                            <option value='good'>Good area</option>
                            <option value='bad'>Bad area</option>
                        </select>
                    </div>

                    <div>
                        <span>Car Type</span>
                        <select value={car} onChange={(e) => setCar(e.target.value)}>
                            <option value='old'>Tired car</option>
                            <option value='general'>General car</option>
                            <option value='new'>New car</option>
                        </select>
                    </div>

                </div>

            </div>
            

            <div className={load ? 'response load' : 'response'}>
                <button onClick={() => getRisk()}>Calculate Risk</button>
                <img src={imgLoad} alt='loader' title='wait while loading' />
                <p>
                    {score}
                </p>
            </div>
           
 
        </div>
    );
}