import React from 'react'
import imgLoader from './loader.gif'
import './loader.css'

export default function Loader(){
    return (
        <div className='loader wrapper'>

            <div className='form'>
                <img src={imgLoader} alt='loading' title='loading...' />
                <br />
                <span>Vänta!</span>
            </div>
            

        </div>
    );
}