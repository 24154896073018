import { useState,useEffect } from "react";
import imgLoader from './loader.gif';
import {isoDateString, evalDateString, isNumber, getDate, dateDiffDays} from './fraud_date_functions';

export default function Fraud({apiUrl}){
    const [contract,setContract] = useState('full');
    const [cDate,setCDate] = useState('date');
    const [aDate,setADate] = useState('date');
    const [accidents,setAccidents] = useState(0);
    const [cp, setCp] = useState('yes');
    const [adv,setAdv] = useState(false)
    const [damage,setDamage]  = useState('small')

    const [vAccidents,setVAccidents] = useState(true);
    const [vCDate,setVCDate] = useState(true);
    const [vADate,setVADate] = useState(true);

    const [load,setLoad] = useState(false);
    const [response,setResponse] = useState('-');

    async function apiTest(){
        const url = apiUrl;
        fetch(url, {method:'get', headers: {"Content-Type":"application/json"}})
        .then(promise => promise.json().then(data => ({data:data, ok: promise.ok})))
        .then(bind => {
            console.log(bind);
        })
        .catch(e=> console.log(`Error: ${e}`));
    }

    async function getFraud(o){
        setLoad(true);
        const json = JSON.stringify(o);
        try{
            const req = await fetch(apiUrl + '/astner.info/fraud', {method:'post', body:json, headers: {"Content-Type":"application/json"}});
            if(req.ok)
            {
                var reqData = await req.json();
                setResponse(reqData["description"]);
            }
            else
                setResponse("(api seem to be down)");
        }
        catch(e)
        {
            setResponse("technical error in api");
        }
        setLoad(false);
    }

    useEffect(() => {
        setCDate(p => {
            const d = isoDateString(getDate(-100));
            return d.substring(0,8)+'01';
        });
        setADate(p => {
            const d = isoDateString(getDate());
            return d.substring(0,10);
        });
    },[]);

    const handleCDate = (v) => {
        setCDate(v);
        setVCDate(evalDateString(v));
    }

    const handleADate = (v) => {
        setADate(v);
        setVADate(evalDateString(v));
    }

    const handleAccidents = (v) => {
        setAccidents(v);
        setVAccidents(isNumber(v));
    }

 
    const getObject = () => {
        var cdt = new Date(cDate);
        var adt = new Date(aDate);
        var days = dateDiffDays(cdt,adt);
        var c_age = 'existing';

        if((days < 10 && cp === 'yes') || (cp === 'no' && days < 30))
            c_age = 'new';
        if(adv)
            c_age = 'advanced';
        
        
        var aNo = parseInt(accidents);
        var history_level = 2;
        if(aNo === 0)
            history_level = 0;
        else if (aNo < 3)
            history_level = 1;

        return {"contract":contract, "counterpart":cp === "yes" ? 1 : 0, "history":history_level, "damage":damage, "contract_age":c_age};
    }

    const evalClick = () => {
        const o = getObject();
        getFraud(o);
    }


    return(
        <div className="component">
            <h2>FRAUD EVALUATION DEMO</h2>
            
            <div className="form">
                <h3>About Your Insurance</h3>

                <div className="form-row">

                    <div>
                        <span>Insurance Type</span>
                        <select value={contract} onChange={(e) => setContract(e.target.value)}>
                                <option value='full'>Full Cover</option>
                                <option value='half'>Half Cover</option>
                        </select>
                    </div>

                    <div className={vCDate ? null : 'text-error'}>
                        <span>Procurement Date</span>
                        <input type='text' value={cDate} onChange={(e) => handleCDate(e.target.value)} />
                    </div>

                    <div className={vAccidents ? null : 'text-error'}>
                        <span>Previous Accidents</span>
                        <input type='text' value={accidents} onChange={(e) => handleAccidents(e.target.value)} />
                    </div>

                </div>

            </div>

            <div className="form">
                <h3>About The Accident</h3>

                <div className="form-row">
                    <div className={vADate ? null : 'text-error'}>
                        <span>Accident Date</span>
                        <input type='text' value={aDate} onChange={(e) => handleADate(e.target.value)} />
                    </div>

                    <div>
                        <span>Counterpart</span>
                        <select value={cp} onChange={(e) => setCp(e.target.value)}>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                        </select>
                    </div>

                    <div>
                        <span>Damage Level</span>
                        <select value={damage} onChange={(e) => setDamage(e.target.value)}>
                            <option value="small">Small</option>
                            <option value='large'>Large</option>
                        </select>
                    </div>

                </div>

                <div className="control">
                    <span><input type='checkbox' checked={adv} onChange={(e) => setAdv(e.target.checked)} />Recently changed insurance type</span>
                </div>

            </div>

            <div className={load ? 'response load' : 'response'}>
                    <button disabled={!(vAccidents && vCDate && vADate)} onClick={() => evalClick()}>CLICK TO EVALUATE</button>

                    <img src={imgLoader} alt='loader' title='wait while loading' />
                    <p>{response}</p>
            </div>

        </div>
    );
}